import { Link } from 'react-router-dom'
import { PopupModal } from '../../components/TopBar/PopupModal/PopupModal'
import styled from 'styled-components'

export function SubMenu({ closeSubMenu, subMenuItems, selecetedMenu }) {
  return (
    <PopupModal closeModal={closeSubMenu} >
      <SubMenuNav className='subMenu'>
        <Link to='#' className="inventory">{selecetedMenu.toUpperCase()}</Link>

        <div onClick={closeSubMenu} className="subMenuItems">
          {subMenuItems.length > 0 && subMenuItems.map(item => (
            <Link key={item.name} to={item.url} className="subMenuItem ">{item.name}</Link>
          ))}
        </div>
      </SubMenuNav>
    </PopupModal>
  )
}

const SubMenuNav = styled.nav`
  display: flex;
  position: fixed;
  left: 88px;
  z-index: 20;
  height: 100%;
  width: 200px;
  padding: 16px;
  flex-direction: column;
  gap: 12px;
  flex-shrink: 0;
  border-left: 1.5px solid #22307a;
  background: var(--DARK-GREY, #0e0e2c);
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.05);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;

  > .inventory {
    color: #7678a3;
    padding: 4px 0px;
    line-height: 16.8px;
    font-size: 12px;
  }
  > .subMenuItems {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 14px;
    line-height: 150%;

    > .subMenuItem {
      color: var(--Color-Tokens-Content-Dark-Secondary, #727272);
      padding: 8px;

      &:hover {
        color: #049af1;
      }
    }
  }
`