import { useEffect, useRef } from 'react'

export function PopupModal({ children, styleOfModal, closeModal }) {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div style={styleOfModal} ref={modalRef}>
      {children}
    </div>
  )
}
