import React from 'react'
import styled from 'styled-components'

export const ShimmerUiForNotes = () => {
    return (
        <ShimmerUi >
            <div className="top">
                <div className="row">
                    <div className="left"></div>
                    <div className="right"></div>
                </div>
                <div className="row">
                    <div className="left"></div>
                    <div className="right"></div>
                </div>
                <div className="row">
                    <div className="left"></div>
                    <div className="right"></div>
                </div>
            </div>
            <div className="mid"></div>
            <div className="bottom">
                <div className="row">
                    <div className="left"></div>
                    <div className="right"></div>
                </div>
                <div className="row">
                    <div className="left"></div>
                    <div className="right"></div>
                </div>
            </div>
        </ShimmerUi>
    )
}

const ShimmerUi = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    .top, .bottom{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .row{
        display: flex;
        justify-content: space-between;
        .left, .right{
            height: 24px;
            border-radius: 4px;
            background-color: var(--PEARL, #ECF1F4);
        }
        .left{
            width: 80%;
        }
        .right{
            width: 10%;
        }
    }
    .mid{
        height: 120px;
        border-radius: 4px;
        background-color: var(--PEARL, #ECF1F4);
    }

`