import { useState } from 'react'
import { format } from 'date-fns';
import { ReactComponent as UpDown } from '../../../../assets/svgIcon/listView/up-down-arrow.svg'
import { ReactComponent as FilterIcon } from '../../../../assets/svgIcon/listView/filter.svg'
import { ReactComponent as Clock } from '../../../../assets/svgIcon/listView/timer.svg'
import { FilterModal } from './FilterModal/FilterModal';
import { ReactComponent as Close } from '../../../../assets/svgIcon/listView/Close.svg'
import { Link } from 'react-router-dom';

export function ListView({ allOrderNames, rowsPerPage, shipmentOrders, tableHeaderList, handleSelectOrder }) {
  const [filteredOrder, setFilteredOrder] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const listOfClasses = {
    'STATUS': 'statusHeader', 'ETA DATE': 'estimatedDate', 'ETA': 'estimatedDate', 'ORDER DATE': 'estimatedDate', 'CREATED BY': 'userPic',
    'WAREHOUSE': 'wareHouse', 'FROM WAREHOUSE': 'wareHouse', 'TO WAREHOUSE': 'wareHouse', 'SUPPLIER': 'supplier', 'SHIPMENT NAME': 'supplier'
  };

  const tableDataElements = (order) => {
    const allElements = {
      STATUS: <td key='status' className='status'><span> <div className='statusBox'></div> {order.status} </span>  </td>,
      'ETA': <td key='eta' className='estimatedDate'><Clock /> {order.eta ? format(order.eta, 'dd MMM yyyy') : 'No ETA'}</td>,
      'ORDER DATE': <td key='date_of_creation' className='estimatedDate'><Clock /> {order.date_of_creation ? format(order.date_of_creation, 'dd MMM yyyy') : ''}</td>,
      'CREATED BY': <td key='creator' className='userPic'><div className='creatorName'>{order.created_by ? order.created_by.full_name : <span className="dataNotPresent">Not Available</span>}</div></td>,
      'FROM WAREHOUSE': <td key='from warehouse' className='wareHouse'>{order.from_warehouses.length ? order.from_warehouses[0].name : <span className="dataNotPresent">Not Applicable</span>}</td>,
      'TO WAREHOUSE': <td key='warehouse' className='wareHouse'>{order.to_warehouse}</td>,
      SUPPLIER: <td key='supplier' className='supplier'>{order.suppliers.length > 0 ? order.suppliers[0]['name'] : <span className="dataNotPresent">Not Applicable</span>}</td>,
      'SHIPMENT NAME': <td key='shipment name' className='supplier'>{order.reference ? order.reference : <span className="dataNotPresent">Not Available</span>}</td>,
    }
    return tableHeaderList.map(header => (
      allElements[header.toUpperCase()]
    ))
  }

  const filterOrderByName = (filtered) => {
    setFilteredOrder(shipmentOrders.filter(order => {
      return filtered.find(name => name === order.name)
    }))
  }

  const clearFilter = () => {
    setFilteredOrder([]);
  }

  const closeFilterModal = () => {
    setIsFilterModalOpen(false)
  }

  return (
    <div className='listView'>
      <table>
        <thead>
          <tr >
            <th className='checkBox'><input type="checkbox" /></th>
            <th className='seriesName'></th>
            <th className='projectName'>
              <UpDown />
              SHIPMENT NO
              {filteredOrder.length ? <span title='Clear filter' onClick={clearFilter}>{filteredOrder.length} <Close /></span>
                : <FilterIcon onClick={() => setIsFilterModalOpen(true)} />
              }
              {isFilterModalOpen && <FilterModal title='Shipment' closeFilterModal={closeFilterModal} filterOrderByName={filterOrderByName} allOrderNames={allOrderNames} />}
            </th>

            {tableHeaderList?.map((item) => {
              const header = item.toUpperCase();
              return (
                <th key={header} className={listOfClasses[header]}>
                  <UpDown />
                  {header}
                  {header !== 'WAREHOUSE' && <FilterIcon />}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody onClick={handleSelectOrder}>
          {tableHeaderList && (filteredOrder.length ? filteredOrder : shipmentOrders).slice(0, rowsPerPage).map((order, ind) => (
            <tr key={order.id}>
              <td className='checkBox'><input type="checkbox" value={order.id} /></td>
              <td className='seriesName'>{ind + 1}</td>
              <td className='projectName'><Link to={`/shipment/international/${order.id}`}> {order.name}</Link></td>

              {tableDataElements(order, tableHeaderList)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
