import { MainMenu } from './Menu/MainMenu/MainMenu'
import { TopBar } from './components/TopBar/TopBar'
import { Shipment } from './page/Shipment/Shipment'

export function NewLayout() {
  return (
    <div className='d-flex overflow-hidden'>
      <MainMenu />
      <div className='flex-grow-1 overflow-hidden d-flex flex-column'>
        <TopBar title='International Shipments' />
        <Shipment />
      </div>
    </div>
  )
}
