import { useSelector } from "react-redux";
import { MilestoneBoard } from "../MilestoneBoard/MilestoneBoard";
import { StatusBoardView } from "../Board/Board";

export function BoardView({ selectedCurrentView, filteredOrderByStatus, filteredOrderByMilestone }) {
    const { status: { allViews: listOfStatusViews }, milestone: { allViews: listOfMilestoneViews } } = useSelector(state => state.newInterface.allViews.boardView);

    return (
        <div className='boardView'>
            {selectedCurrentView.sub_type === 'milestone' && listOfMilestoneViews[selectedCurrentView.value]?.length > 0 ?
                <MilestoneBoard selectedCurrentView={selectedCurrentView} filteredOrderByMilestone={filteredOrderByMilestone} />
                :
                listOfStatusViews[selectedCurrentView.value]?.length && listOfStatusViews[selectedCurrentView.value].map((status, ind) => (
                    <StatusBoardView key={status} filteredOrderByStatus={filteredOrderByStatus[status]} status={status} ind={ind} />
                ))
            }
        </div>
    )
}




