import authReducer from "./slice/authSlice";
import chatReducer from "./slice/chatSlice";
import complianceReducer from "./slice/complianceSlice";
import currencyReducer from "./slice/currencySlice";
import fileReducer from "./slice/fileSlice";
import integrationSlice from "./slice/integrationSlice";
import invoiceReducer from "./slice/invoiceSlice";
import miscReducer from "./slice/miscSlice";
import orderReducer from "./slice/orderSlice";
import plannerReducer from "./slice/plannerSlice";
import productSlice from "./slice/productSlice";
import purchaseOrderReducer from "./slice/purchaseOrderSlice";
import receiptReducer from "./slice/receiptSlice";
import reportReducer from "./slice/reportSlice";
import shipmentReducer from "./slice/shipmentSlice";
import supplierReducer from "./slice/supplierSlice";
import userReducer from "./slice/userSlice";
import warehouseReducer from "./slice/warehouseSlice";
import webSocketReducer from "./slice/webSocketSlice";
import newInterfaceReducer from "./slice/newInterfaceSlice";
import { apiSlice } from "./apiSlice/apiSlice";
import { configureStore } from "@reduxjs/toolkit";
import { sseMiddleware } from "./middleware";

// Configure the Redux store.
export const store = configureStore({
  // Enable Redux DevTools in development mode for easier debugging.
  devTools: process.env.NODE_ENV !== "production",

  // Middleware Setup.
  middleware: getDefaultMiddleware =>
    // Start with the default middleware provided by Redux Toolkit.
    getDefaultMiddleware()
      // Add the middleware from the apiSlice for handling async actions and side effects.
      .concat(apiSlice.middleware)
      // Add the middleware from the middlewares for handling server side event side effects.
      .concat(sseMiddleware),

  // Define the reducers for the store.
  // Each reducer manages its own part of the global state.
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    chat: chatReducer,
    compliance: complianceReducer,
    currency: currencyReducer,
    file: fileReducer,
    integration: integrationSlice,
    invoice: invoiceReducer,
    misc: miscReducer,
    order: orderReducer,
    planner: plannerReducer,
    product: productSlice,
    purchaseOrder: purchaseOrderReducer,
    receipt: receiptReducer,
    report: reportReducer,
    shipment: shipmentReducer,
    supplier: supplierReducer,
    user: userReducer,
    warehouse: warehouseReducer,
    webSocket: webSocketReducer,
    newInterface: newInterfaceReducer
  },
});
