import { useEffect, useRef, useState } from 'react'
import { ReactComponent as Close } from '../../../../../assets/svgIcon/listView/Close.svg'
import { ReactComponent as Search } from '../../../../../assets/svgIcon/listView/search.svg'

export function FilterModal({ title, allOrderNames, filterOrderByName, closeFilterModal }) {
  const searchBoxRef = useRef(null);
  const widthOfSearchedOrder = useRef(null);
  const [isSearchedItemRender, setIsSearchedItemRender] = useState(false);
  const [isSelectedAllSearchOrder, setIsSelectedAllSearchOrder] = useState(false);
  const [searchedOrder, setSearchedOrder] = useState([]);
  const [selectedSearchOrder, setSelectedSearchOrder] = useState([]);

  useEffect(() => {
    widthOfSearchedOrder.current = searchBoxRef.current.getBoundingClientRect().width - 90;
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    if (query.length > 2) {
      setIsSearchedItemRender(true)
      setSearchedOrder(allOrderNames.filter(name => name.toLowerCase().includes(query.toLowerCase())));
    }
    else {
      setIsSearchedItemRender(false)
      setSearchedOrder([]);
    }

  }

  const handleOrderCheckbox = (e) => {
    if (e.target.tagName === 'LABEL') return;
    if (e.target.value === 'selectAll') {
      if (e.target.checked) {
        setIsSelectedAllSearchOrder(true);
        setSelectedSearchOrder([...searchedOrder]);
      }
      else {
        setIsSelectedAllSearchOrder(false);
        setSelectedSearchOrder([]);
      }
      return;
    }

    if (e.target.checked) {
      setSelectedSearchOrder([...selectedSearchOrder, e.target.value])
    }
    else {
      setSelectedSearchOrder(selectedSearchOrder.filter(name => name !== e.target.value))
      if (isSelectedAllSearchOrder) {
        setIsSelectedAllSearchOrder(false)
      }

    }
  };

  const handleCloseOfSearch = () => {
    setSelectedSearchOrder([]);
    setSearchedOrder([])
  }

  const afterItemSelectedRenderInContainer = () => {
    let elements = ``;
    if (selectedSearchOrder.length >= 2 && (selectedSearchOrder[0].length + selectedSearchOrder[1].length) * 7 < widthOfSearchedOrder.current) {
      elements = <>
        <div className='searchedTextBox'>
          <span className='searchedText'>
            {selectedSearchOrder[0]}
          </span>
          <span className='searchedText'>
            {selectedSearchOrder[1]}
          </span>
          {selectedSearchOrder.length > 2 && <span className='searchedText'>
            +{selectedSearchOrder.length - 2}
          </span>}
        </div>
        <Close onClick={handleCloseOfSearch} />
      </>

    } else {
      elements = <>
        <div className='searchedTextBox'>
          <span className='searchedText'>
            {selectedSearchOrder[0]}
          </span>
          {selectedSearchOrder.length !== 1 && <span className='searchedText'>
            +{selectedSearchOrder.length === 2 ? 1 : selectedSearchOrder.length - 2}
          </span>}
        </div>
        <Close onClick={handleCloseOfSearch} />
      </>
    }
    return elements;
  }

  const isCheckedBox = (name) => {
    return selectedSearchOrder.includes(name);
  }

  const applyFilters = () => {
    filterOrderByName(selectedSearchOrder);
    closeFilterModal();
  }

  return (
    <div className='filterModal'>
      <h3> Filter {title} No <Close onClick={closeFilterModal} /> </h3>

      <div ref={searchBoxRef} className="searchContainer">
        {!isSearchedItemRender && selectedSearchOrder.length ?
          afterItemSelectedRenderInContainer()
          : <>
            <input onChange={handleSearch} onClick={() => setIsSearchedItemRender(!isSearchedItemRender)} type="text" name="" id="" placeholder='Search project name' />
            <div className="searchIcon">
              <Search />
            </div>
          </>

        }
        {(isSearchedItemRender && searchedOrder.length > 0) &&
          <div onClick={handleOrderCheckbox} className="searchedOrder">
            <label className="searchedItem" >
              <input type="checkbox" checked={isSelectedAllSearchOrder} onChange={() => ''} value='selectAll' name="selectAll" id="" />
              All Test Values
            </label>
            {searchedOrder.map(name => (
              <label key={name} className="searchedItem" name='label'>
                <input type="checkbox" value={name} checked={isCheckedBox(name)} id="" onChange={() => ''} />
                {name}
              </label>
            ))}
          </div>}
      </div>
      <div className="btnGrp">
        <button onClick={closeFilterModal} className='btnForModal'>Cancel</button>
        <button className={selectedSearchOrder.length ? 'btnForModal applyBtn activeBtn' : 'btnForModal applyBtn'} onClick={applyFilters} disabled={selectedSearchOrder.length === 0} >Apply Filter</button>
      </div>
    </div>
  )
}

