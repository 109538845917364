import styled from 'styled-components'
import { useState } from "react";
import { SearchDropDown } from '../DropDown/DropDown';
import { useSelector } from 'react-redux';


export const ViewRules = () => {
    const { boardView: { status: { nameOfViews: listOfViewForStatus }, milestone: { nameOfViews: listOfViewForMilestone } }, listView: { nameOfViews: listOfViewsForTable } } = useSelector(state => state.newInterface.allViews);
    const [condition, setCondition] = useState(null);

    return (
        <ViewRulesContainer>
            <div className='filterForColumn search-dropdown-container'>
                <SearchDropDown listOfViewForStatus={listOfViewForStatus}
                    listOfViewsForTable={listOfViewsForTable}
                    listOfViewForMilestone={listOfViewForMilestone} fieldName="View Name" />
                <SearchDropDown options={["SHIPMENT NAME", "SUPPLIER", "FROM WAREHOUSE", "TO WAREHOUSE", "ORDER DATE", "ETA", "CREATED BY", "STATUS"
                ]} fieldName="Field" />

                <SearchDropDown options={["Equals", "Not Equals"]} fieldName="Condition" />
                <SearchDropDown options={["Seller Location", "Fully Received", "Gated Out"]} fieldName="Value" />
                <SearchDropDown setCondition={setCondition} options={["OR", "AND"]} fieldName="Criteria" />

            </div>

            {
                condition &&
                <label className='pattern'>
                    <p>Criteria Pattern:  (1 {condition} 2) </p>
                    {/* <input type="text" placeholder='Criteria Pattern: ' /> */}
                </label>

            }
        </ViewRulesContainer>
    )
}

const ViewRulesContainer = styled.div`
    .filterForColumn{
        display: flex;
        flex-wrap: wrap;

        }

`



// const ViewRules = () => {
//     const [selected, setSelected] = useState("Item Name");

//     const handleChange = (event) => {
//         setSelected(event.target.value);
//     };

//     return (
//         <ViewRulesContainer className="dropdown-container">
//             <label className="dropdown-label">Field</label>
//             <select
//                 className="dropdown-select"
//                 value={selected}
//                 onChange={handleChange}
//             >
//                 <option value="Item Name">Item Name</option>
//                 <option value="Item Price">Item Price</option>
//                 <option value="Item Quantity">Item Quantity</option>
//             </select>
//         </ViewRulesContainer>
//     );
// };

// const Container = styled.div`
//   width: 250px;
//   font-family: Arial, sans-serif;
//   position: relative;
//   margin: 20px;
// `;

// const Label = styled.label`
//   font-size: 14px;
//   color: #555;
//   margin-bottom: 5px;
//   display: block;
// `;

// const SelectWrapper = styled.div`
//   position: relative;
// `;

// const StyledSelect = styled.select`
//   width: 100%;
//   padding: 10px;
//   font-size: 16px;
//   color: #333;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   background-color: #fff;
//   appearance: none;
//   cursor: pointer;

//   &:focus {
//     outline: none;
//     border-color: #007bff;
//   }
// `;

// const DropdownArrow = styled.span`
//   position: absolute;
//   top: 50%;
//   right: 10px;
//   pointer-events: none;
//   transform: translateY(-50%);
//   font-size: 12px;
//   color: #007bff;
// `;

// const Option = styled.option`
//   font-size: 14px;
//   color: #333;
//   padding: 10px;

//   &:checked {
//     background-color: #007bff;
//     color: #fff;
//   }
// `;

// const ViewRules = () => {
//     const [selectedOption, setSelectedOption] = useState("Item Name");

//     const handleChange = (event) => {
//         setSelectedOption(event.target.value);
//     };

//     return (
//         <Container>
//             <Label>Field</Label>
//             <SelectWrapper>
//                 <StyledSelect value={selectedOption} onChange={handleChange}>
//                     <Option value="Item Name">Item Name</Option>
//                     <Option value="Assignee">Assignee</Option>
//                     <Option value="Status Type">Status Type</Option>
//                     <Option value="Item Status">Item Status</Option>
//                     <Option value="Start Date">Start Date</Option>
//                 </StyledSelect>
//                 <DropdownArrow>&#9662;</DropdownArrow>
//             </SelectWrapper>
//         </Container>
//     );
// };



// export { ViewRules };
