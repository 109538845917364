import { setNotification } from "../slice/miscSlice";
import { editMilestoneItem, setAllFilterCondition, setAllFilterField, setAllViews, setMilestoneOrders, setSingleView, updateFilterShipmentByMilestone } from "../slice/newInterfaceSlice";
import { apiSlice } from "./apiSlice";
import { shipmentApiSlice } from "./shipmentApiSlice";

export const newInterfaceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addMilestone: builder.mutation({
            query: (data) => ({
                url: `/api_interface/milestone/`,
                method: "POST",
                body: data,
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(res =>
                    dispatch(newInterfaceApiSlice.util.invalidateTags(['milestonesUpdateList']))
                ).catch(err => { })
            },
        }),
        getMilestones: builder.query({
            query: params =>
                params ? `api_interface/milestone/?${params}` : "api_interface/milestone/",
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => dispatch(setMilestoneOrders(result.data))).catch(error => { });
            },
            providesTags: _ => ['milestonesUpdateList'],
        }),
        updateMilestoneDetails: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api_interface/milestone/${id}`,
                method: "PATCH",
                body: data,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                await queryFulfilled
                    .then(result => dispatch(editMilestoneItem(result.data)))
                    .catch(error => { });
                dispatch(newInterfaceApiSlice.util.invalidateTags(['milestonesUpdateList']))
            },
        }),
        deleteMilestone: builder.mutation({
            query: (id) => ({
                url: `/api_interface/milestone/${id}`,
                method: "DELETE",
                body: {},
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                await queryFulfilled;
                dispatch(
                    setNotification({
                        type: "success",
                        message: "Done! The milestone has been removed successfully..",
                    }),
                )
                dispatch(newInterfaceApiSlice.util.invalidateTags(['milestonesUpdateList']))
            },
        }),

        addTrackingInfo: builder.mutation({
            query: (data) => ({
                url: `/api_shipment/consignment_tracking/`,
                method: "POST",
                body: data,
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(_ => {
                    dispatch(shipmentApiSlice.util.invalidateTags(["consignmentList"]))
                })
            },
        }),

        updateTrackingDetails: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api_shipment/consignment_tracking/${id}/`,
                method: "PATCH",
                body: data,
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(_ => {
                    dispatch(shipmentApiSlice.util.invalidateTags(["consignmentList"]))
                })
            },
        }),

        deleteNote: builder.mutation({
            query: (id) => ({
                url: `/api_chat/note/${id}`,
                method: "DELETE",
                body: {},
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled, getState }) => {
                queryFulfilled.then(result => {
                    if (getState().shipment.consignment?.notes?.length === 1) {
                        dispatch(shipmentApiSlice.util.invalidateTags(["consignmentList"]))
                    }
                }).catch(error => { });
            },
        }),
        updateNote: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api_chat/note/${id}/`,
                method: "PATCH",
                body: data,
            }),
        }),
        createView: builder.mutation({
            query: (data) => ({
                url: `/api_interface/view/`,
                method: "POST",
                body: data,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                await queryFulfilled;
                dispatch(newInterfaceApiSlice.util.invalidateTags(["viewUpdate"]))
            },
        }),
        getView: builder.query({
            query: () => "api_interface/view/",
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => dispatch(setAllViews(result.data))).catch(error => { });
            },
            providesTags: _ => ["viewUpdate"],
        }),
        getSingleView: builder.query({
            query: (id) => `api_interface/view/${id}`,
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => dispatch(setSingleView(result.data))).catch(error => { });
            }
        }),
        updateView: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api_interface/view/${id}/`,
                method: "PATCH",
                body: data,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                await queryFulfilled;
                dispatch(newInterfaceApiSlice.util.invalidateTags(["viewUpdate"]))
            },
        }),
        deleteView: builder.mutation({
            query: (id) => ({
                url: `/api_interface/view/${id}`,
                method: "DELETE",
                body: {},
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(_ => {
                    dispatch(
                        setNotification({
                            type: "success",
                            message: "View successfully deleted—it's no longer in the list!",
                        }),
                    )
                    dispatch(newInterfaceApiSlice.util.invalidateTags(["viewUpdate"]))
                })
            },
        }),
        handleMilestoneView: builder.mutation({
            query: ({ id, data }) => ({
                url: id === "Uncategorized" ? `/api_interface/milestone/bulk_remove_relation` : `/api_interface/milestone/${Number(id)}/bulk_update_relation`,
                method: 'PUT',
                body: data
            }),
            onQueryStarted: (data, { dispatch }) => {
                dispatch(updateFilterShipmentByMilestone(data))
            },
        }),
        // handleMilestoneViewToUncategorized: builder.mutation({
        //     query: ({ id, data }) => ({
        //         url: `/api_interface/milestone/bulk_remove_relation`,
        //         method: 'PUT',
        //         body: data
        //     }),
        //     onQueryStarted: (data, { dispatch }) => {
        //         dispatch(updateFilterShipmentByMilestone(data))
        //     },
        // }),
        getAllFilterCondition: builder.query({
            query: () => "/api_rule/filter_condition/",
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => dispatch(setAllFilterCondition(result.data))).catch(error => { });
            },
        }),
        getAllFilterField: builder.query({
            query: () => "/api_rule/filter_field/",
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => dispatch(setAllFilterField(result.data))).catch(error => { });
            },
        }),
        getAllFilterCriteria: builder.query({
            query: () => "/api_rule/filter_criteria/",
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(result => dispatch(setAllFilterField(result.data))).catch(error => { });
            },
        }),
        createFilterCriteria: builder.mutation({
            query: (data) => ({
                url: `/api_rule/filter_criteria/`,
                method: "POST",
                body: data,
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(res => dispatch(newInterfaceApiSlice.util.invalidateTags(["viewUpdate"]))).catch(err => { });
                // dispatch(newInterfaceApiSlice.util.invalidateTags(["viewUpdate"]))
            },
        }),
        updateFilterCriteria: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api_rule/filter_criteria/${id}/`,
                method: "PUT",
                body: data,
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then().catch(err => { })
            },
        }),
        deleteFilterCriteria: builder.mutation({
            query: (id) => ({
                url: `/api_rule/filter_criteria/${id}`,
                method: "DELETE",
                body: {},
            }),
            onQueryStarted: (_, { dispatch, queryFulfilled }) => {
                queryFulfilled.then(_ => {
                    dispatch(
                        setNotification({
                            type: "success",
                            message: "View reset successfully—back to a fresh start!",
                        })
                    );
                    dispatch(newInterfaceApiSlice.util.invalidateTags(["viewUpdate"]))
                })
            },
        }),


    }),
});
export const {
    useAddMilestoneMutation,
    useCreateViewMutation,
    useGetMilestonesQuery,
    useUpdateMilestoneDetailsMutation,
    useUpdateTrackingDetailsMutation,
    useUpdateNoteMutation,
    useDeleteMilestoneMutation,
    useAddTrackingInfoMutation,
    useDeleteNoteMutation,
    useGetViewQuery,
    useGetSingleViewQuery,
    useUpdateViewMutation,
    useDeleteViewMutation,
    useHandleMilestoneViewMutation,
    useGetAllFilterConditionQuery,
    useGetAllFilterFieldQuery,
    useGetAllFilterCriteriaQuery,
    useCreateFilterCriteriaMutation,
    useUpdateFilterCriteriaMutation,
    useDeleteFilterCriteriaMutation,


} = newInterfaceApiSlice;
