import { useDispatch, useSelector } from "react-redux";
import { handleFilterShipmentByMilestone } from "../../../../../../store/slice/newInterfaceSlice";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { GeneralModal } from "../../../../components/GeneralModal/GeneralModal";
import { TrackingInfo } from "../TrackingInfo/TrackingInfo";
import { NotesModal } from "../Notes/Notes";
import { ReactComponent as TrackIcon } from '../../../../assets/svgIcon/shipment/track.svg'
import { ReactComponent as NotesIcon } from '../../../../assets/svgIcon/shipment/Notes.svg'
import { useHandleMilestoneViewMutation } from "../../../../../../store/apiSlice/newInterfaceApiSlice";
import { BlurModal } from "../BlurModal/BlurModal";

export const MilestoneBoard = ({ selectedCurrentView }) => {
    const { results: consignments } = useSelector(state => state.shipment.consignments);
    const { allViews: { boardView: { milestone } }, milestoneList, filterShipmentByMilestone, consignmentMappedById } = useSelector(state => state.newInterface);
    const dispatch = useDispatch();
    const [milestoneListForLocal, setMilestoneListForLocal] = useState([]);
    const [isTrackModalOpen, setIsTrackModalOpen] = useState(false);
    const [updateMilestoneView] = useHandleMilestoneViewMutation();

    const [isOpenNotes, setIsOpenNotes] = useState(false)
    const [orderId, setorderId] = useState(null)
    const [orderIdForNotes, setOrderIdForNotes] = useState(null)

    const openTrackModal = (id, orderName, trackData) => {
        setIsTrackModalOpen(true);
        setorderId({ id: id, name: orderName, trackInfo: trackData })
    }
    const closeTrackModal = () => {
        setIsTrackModalOpen(false)
        setorderId(null)
    }
    const openNotesModal = (id, orderName, is_note_available) => {
        setIsOpenNotes(true)
        setOrderIdForNotes({ id, name: orderName, is_note_available })
    }
    const closeNotesModal = () => {
        setIsOpenNotes(false)
        setOrderIdForNotes(null)
    }


    useEffect(() => {
        setMilestoneListForLocal(state => {
            const order = [{ name: 'Uncategorized', id: 'Uncategorized' }];
            const allMilestoneId = {};
            milestoneList.forEach(item => allMilestoneId[item.id] = item);
            milestone.allViews[selectedCurrentView.value].forEach(item => {
                if (allMilestoneId[item]) {
                    order.push({ name: allMilestoneId[item]['name'], id: item, color: allMilestoneId[item].meta.color })
                }
            }
            )
            return order;
        });
    }, [selectedCurrentView.value, milestone, milestoneList])

    useEffect(() => {
        dispatch(handleFilterShipmentByMilestone(consignments))
    }, [dispatch, consignments])


    const handleDragStart = (e, sourceIndex, targetIndex) => {
        e.dataTransfer.setData('text/plain', `${sourceIndex}-${targetIndex}`);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, targetIndex) => {
        e.stopPropagation();
        const [sourceIndex, targetIndexFromDrag] = e.dataTransfer.getData('text/plain').split('-');
        updateMilestoneView({ id: targetIndex, sourceIndex, data: { ids: [Number(targetIndexFromDrag)], module: 'consignment' } })
    };

    const colorByStatus = {
        'Fully Received': 'green', 'Seller Location': 'sky', 'Partially Received': 'orange', 'Cancelled': 'red',
    }

    return (
        <>
            {isTrackModalOpen &&
                <BlurModal>
                    <GeneralModal closeModal={closeTrackModal} titleOfModal={`Tracking Information: ${orderId.name}`} >
                        <TrackingInfo orderId={orderId} trackInfoFromParent={orderId.trackInfo} />
                    </GeneralModal>
                </BlurModal>
            }
            {
                isOpenNotes &&
                <BlurModal>
                    <GeneralModal widthOfGeneralModal={'50vw'} titleOfModal={`Notes: ${orderIdForNotes.name}`} closeModal={closeNotesModal} >
                        <NotesModal key={orderIdForNotes.id} orderId={orderIdForNotes.id} isNoteAvailable={orderIdForNotes.is_note_available} />
                    </GeneralModal>
                </BlurModal>
            }
            {milestoneListForLocal.length > 0 && milestoneListForLocal.map((milestoneName, parentInd) => (
                <div key={milestoneName.name} className="board">
                    <div className="boardHeader">
                        <h3 className={`colorBox ${milestoneName.color}`}><p className='tickBox'></p> <p>{milestoneName.name}</p> </h3>
                        <p className='countNo'>{filterShipmentByMilestone[milestoneName.id]?.length}</p>
                    </div>
                    <div className="tilesContainer" key={milestoneName.name} onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, milestoneName.id)}>
                        {
                            filterShipmentByMilestone[milestoneName.id].length > 0 &&
                            filterShipmentByMilestone[milestoneName.id]?.map((orderId) => {
                                const order = consignmentMappedById[orderId];
                                return (
                                    <div key={order.id} className='boardTile'
                                        draggable
                                        onDragStart={(e) => handleDragStart(e, milestoneName.id, order.id)}
                                    >
                                        <div className='tileHeader'>
                                            <div className="left">
                                                <input className='checkBox' type="checkbox" />
                                                <Link to={`/shipment/international/${order.id}`} >{order.name} </Link>
                                            </div>

                                            <div className="btnGroup">
                                                <span className={` ${order.tracking ? 'iconInBoat' : ''} iconWrapper`} title="Tracking Info" onClick={() => openTrackModal(order.id, order.name, order.tracking)}><TrackIcon /> </span>
                                                <span className={` ${order.is_note_available ? 'iconInBoat' : ''} iconWrapper`} title="Notes" onClick={() => openNotesModal(order.id, order.name, order.is_note_available)}><NotesIcon /> </span>
                                            </div>
                                        </div>

                                        <div className="orderInfo">
                                            <p>To Warehouse: {order.to_warehouse}</p>
                                            <p>Company: Mainfreight</p>
                                            <p>ASN number: 6002 </p>
                                            <p className={`colorBox colorBoxWidth ${colorByStatus[order.status]}`}> <span className='tickBox'></span> {order.status}</p>
                                        </div>
                                        <p className='eta'>ETA: {order.eta ? format(order.eta, 'dd MMM yyyy, hh:mm a') : 'No eta time'}</p>
                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                </div>
            ))
            }
        </>
    )
}
