import styled from "styled-components"

export const InputText = ({ text, setText, labelOfInput, type, placeholder, inputName, handleKeyDown = () => { }, heightOfInput, widthOfInput }) => {
    return (
        <Label height={heightOfInput} width={widthOfInput} className="labelForText"> {labelOfInput}
            <input type={type ? type : 'text'} name={inputName} value={text} onKeyDown={handleKeyDown} onChange={setText} placeholder={placeholder ? placeholder : "Type here"} />
        </Label>
    )
}

const Label = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    color: var(--GREY, #4A4A68);
    line-height: 20px;
    font-weight: 400;
    width: 100%;

    input{
        display: flex;
        height: ${(props) => props.height || '40px'};
        width: ${(props) => props.width || 'auto'};
        padding: 8px 4px 8px 12px;
        color: var(--GREY, #4A4A68);
        font-weight: 400;
        line-height: 22px;
        border-radius: 4px;
        border: 1px solid var(--LIGHT-ASH, #B9B9C6);
        background-color: var(--Cloud, #FCFDFE);
        outline: none;

        &:hover{
            border-color: var(--DARK-BLUE, #1249BF);
        }
        &:focus{
           border-color : var(--DARK-BLUE, #1249BF);
           .labelForText{
               border-color : var(--DARK-BLUE, #1249BF) !important;
           }
        }
    }
    `