export const colorcomb = {
    green: { color: '#14804A', bg: '#E1FCEF' },
    orange: { color: '#AA5B00', bg: '#FCF2E6' },
    red: { color: '#CF324A', bg: '#FFEDEF' },
    sky: { color: '#262641', bg: '#CCE5FF' },
    processing: { color: '#1677ff', bg: '#e6f4ff' },
    success: { color: '#52c41a', bg: 'f6ffed' },
    warning: { color: '#faad14', bg: '#fffbe6' },
    magenta: { color: '#c41d7f', bg: '#fff0f6' },
    volcano: { color: '#d4380d', bg: '#fff2e8' },
    cyan: { color: '#08979c', bg: '#e6fffb' },
    geekblue: { color: '#1d39c4', bg: '#f0f5ff' },
    purple: { color: '#531dab', bg: '#f9f0ff' },
}

export const colorNames = ['green', 'orange', 'red', 'sky', 'processing', 'success', 'warning', 'magenta', 'volcano', 'cyan', 'geekblue', 'purple']
