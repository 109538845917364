import { ListIcon } from '../../../../assets/svgIcon/listView/ListIcon'
import { BoardIcon } from '../../../../assets/svgIcon/listView/Board'
import { Eye } from '../../../../assets/svgIcon/listView/Eye'
import { ReactComponent as MenuIcon } from '../../../../assets/svgIcon/shipment/Menu.svg'
import { SelectComponent } from '../SelectComponent/SelectComponent.jsx'
import { useState } from 'react'
import { Milestone } from '../../../../components/Milestone/Milestone.jsx'
import { ViewRules } from '../ViewRules/ViewRules.jsx'
import { BlurModal } from '../BlurModal/BlurModal.jsx'
import { GeneralModal } from '../../../../components/GeneralModal/GeneralModal.jsx'

export function OptionsForView({ boardView, setBoardView, selectedOrderLength, titleForRows, setRowsPerPage, setisOpenCreateView, selectedCurrentView, setSelectedCurrentView, setEditView }) {
    const rowsPerPage = ['All', '10 rows', '20 rows', '40 rows', '60 rows', '100 rows'];
    const [isOpenMilestone, setIsOpenMilestone] = useState(false);
    const [isOpenViewRules, setIsOpenViewRules] = useState(false)

    const openViewRules = () => {
        setIsOpenViewRules(true);
    }
    const closeViewRules = () => {
        setIsOpenViewRules(false);
    }

    const openMileStoneView = () => {
        setIsOpenMilestone(true);
    }
    const closeMilestoneModal = () => {
        setIsOpenMilestone(false);
    }

    const handleListView = () => {
        setBoardView(false);
        setSelectedCurrentView({ value: 'Default View', sub_type: null })
    }
    const handleBoardView = () => {
        setBoardView(true);
        setSelectedCurrentView({ value: 'Default View', sub_type: null })
    }
    const handleViewRules = () => {
        closeViewRules()
    }

    return (
        <div className='options'>
            {
                isOpenViewRules &&
                <BlurModal >
                    <GeneralModal handleModalWork={handleViewRules} closeModal={closeViewRules} btnName='Apply' titleOfModal='View Rules' widthOfGeneralModal='60vw'>
                        <ViewRules />
                    </GeneralModal>
                </BlurModal>
            }

            <nav className={selectedOrderLength ? 'leftSide activeState' : 'leftSide'}>
                <ul>
                    <li className='tableButton' onClick={openViewRules}>Rules </li>
                    <li className='tableButton'>Cancel Shipment </li>
                </ul>
            </nav>

            <nav className="rightSide">
                <div className='selectOptions'>
                    <Milestone isOpenMilestone={isOpenMilestone} openMilestoneModal={openMileStoneView} closeMilestoneModal={closeMilestoneModal} />
                    <div className={isOpenMilestone ? 'milestoneBtn activeMilestone' : 'milestoneBtn'} onClick={openMileStoneView}>Milestones</div>

                    <SelectComponent boardView={boardView} setisOpenCreateView={setisOpenCreateView} selectedCurrentView={selectedCurrentView}
                        setSelectedCurrentView={setSelectedCurrentView} title={selectedCurrentView.value} LeftIcon={Eye}
                        setEditView={setEditView}
                    />

                    <SelectComponent setRowsPerPage={setRowsPerPage} title={titleForRows} data={rowsPerPage} />
                </div>

                <div className='viewIcon'>
                    <button className={boardView ? 'listIcon' : 'listIcon activeIcon'} onClick={handleListView}>
                        <ListIcon color={boardView ? '#8C8CA2' : `var(--DARK-BLUE, #1249BF)`} />
                    </button>
                    <button className={boardView ? 'boardIcon activeIcon' : 'boardIcon'} onClick={handleBoardView}>
                        <BoardIcon color={boardView ? `var(--DARK-BLUE, #1249BF)` : '#8C8CA2'} />
                    </button>
                </div>

                <span className='menuIcon'><MenuIcon /></span>
            </nav>
        </div>
    )
}
