import { useEffect, useState } from 'react'
import { Down } from '../../../../assets/svgIcon/listView/Down'
import { ReactComponent as UpIcon } from '../../../../assets/svgIcon/listView/UpBlue.svg'
import { ReactComponent as Check } from '../../../../assets/svgIcon/listView/check.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/svgIcon/shipment/DeleteOutlined.svg'
import { PopupModal } from '../../../../components/TopBar/PopupModal/PopupModal';
import { useSelector } from 'react-redux';
import { useDeleteViewMutation } from '../../../../../../store/apiSlice/newInterfaceApiSlice'
import { DeleteModal } from '../../../../components/DeleteModal/DeleteModal'

export function SelectComponent({ title, boardView, data, LeftIcon, setisOpenCreateView, setRowsPerPage, selectedCurrentView, setSelectedCurrentView, setEditView }) {

  const { count: totalOrderCount } = useSelector(state => state.shipment.consignments);
  const { boardView: { status: { nameOfViews: listOfViewForStatus }, milestone: { nameOfViews: listOfViewForMilestone } }, listView: { nameOfViews: listOfViewsForTable } } = useSelector(state => state.newInterface.allViews);
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(null);
  const [deleteView] = useDeleteViewMutation();


  const closeModal = () => setIsOpenSelect(false)
  const openModal = () => setIsOpenSelect(true)

  const closeDeleteModal = () => setIsOpenDeleteModal(false)
  const openDeleteModal = (e, id, name) => {
    e.stopPropagation();
    setIsOpenDeleteModal(true)
    setDeleteData({ id, name })
  }

  const handleDeleteModal = () => {
    deleteView(deleteData.id)
    closeDeleteModal();
  }

  const handleSelect = (value, id) => {
    if (value === 'All') {
      setRowsPerPage(totalOrderCount);
      closeModal()
      return
    }
    if (value.slice(-4) === 'rows') {
      setRowsPerPage(value.split('rows')[0])
      closeModal()
      return;
    }

    setSelectedCurrentView({ value: value, sub_type: null, id: id });
    setSelectedValue(value)
    closeModal()
  }

  const handleSelectForStatus = (e, value, id) => {
    e.stopPropagation();
    setSelectedCurrentView({ value: value, sub_type: 'status', id: id });
    setSelectedValue(value);
    closeModal()
  }

  const handleSelectForMilestone = (e, value, id) => {
    e.stopPropagation();
    setSelectedCurrentView({ value: value, sub_type: 'milestone', id: id });
    setSelectedValue(value)
    closeModal()
  }

  const openCreateView = (e) => {
    e.stopPropagation();
    setisOpenCreateView(true);
    setIsOpenSelect(false)
  }

  const editView = (e) => {
    e.stopPropagation();
    setisOpenCreateView(true);
    setIsOpenSelect(false);
    setEditView(true)
  }

  useEffect(() => {
    if (selectedCurrentView?.value) {
      setSelectedValue(selectedCurrentView.value)
    }
  }, [selectedCurrentView])

  return (

    <div className='selectBox' >
      <div className={isOpenSelect ? 'activeSelectTitle selectTitle' : "selectTitle"} onClick={openModal} >
        {!data?.length > 0 && <LeftIcon color={isOpenSelect ? '#2060EA' : '#4A4A68'} />}
        {title}
        {isOpenSelect ? <UpIcon /> : <Down />}
      </div>

      {isOpenDeleteModal && <DeleteModal
        handleDeleteModal={handleDeleteModal}
        closeDeleteModal={closeDeleteModal}
        nameOfView={deleteData.name}
      />}

      {isOpenSelect &&
        <PopupModal styleOfModal={styleOfModal} closeModal={closeModal}>
          <div className="optionsOfSelect">
            {!data?.length > 0 && <div className="optionItem" onClick={() => handleSelect('Default View')}>
              Default View
              {selectedValue === 'Default View' && <Check />}
            </div>}

            {data?.length > 0 ? data.map(item => (
              <div key={item} className="optionItem" onClick={() => handleSelect(item)}>
                {item}
                {selectedValue === item && <Check />}
              </div>
            )) :

              boardView ?
                (listOfViewForStatus?.length > 0 || listOfViewForMilestone?.length > 0) &&
                <>
                  {listOfViewForStatus.length > 0 && <div className="listOfView">
                    <p >Status</p>
                    {listOfViewForStatus.map(item =>
                      <div key={item.name} className="optionItem" onClick={(e) => handleSelectForStatus(e, item.name, item.id)}>
                        {item.name}
                        <div className="btnGroup">
                          {selectedValue === item.name && <Check />}
                          <span className="iconWrapper" onClick={(e) => openDeleteModal(e, item.id, item.name)}> <DeleteIcon /> </span>
                        </div>
                      </div>
                    )}
                  </div>
                  }

                  {listOfViewForMilestone.length > 0 && <div className="listOfView">
                    <p>Milestone</p>
                    {listOfViewForMilestone.map(item =>
                      <div key={item.name} className="optionItem" onClick={e => handleSelectForMilestone(e, item.name, item.id)}>
                        {item.name}

                        <div className="btnGroup">
                          {selectedValue === item.name && <Check />}
                          <span className="iconWrapper" onClick={(e) => openDeleteModal(e, item.id, item.name)} > <DeleteIcon /> </span>
                        </div>
                      </div>
                    )}
                  </div>
                  }
                </>
                : listOfViewsForTable?.length > 0 && listOfViewsForTable.map(item =>
                  <div key={item.name} className="optionItem" onClick={() => handleSelect(item.name, item.id)}>
                    {item.name}

                    <div className="btnGroup">
                      {selectedValue === item.name && <Check />}
                      <span className="iconWrapper" onClick={(e) => openDeleteModal(e, item.id, item.name)}> <DeleteIcon /> </span>
                    </div>
                  </div>
                )
            }

            {(listOfViewForMilestone?.length > 0 || listOfViewForStatus?.length > 0) && <>
              <div className="optionItem editView" onClick={editView}>
                Edit Current View
              </div>
              <div className="optionItem editView" onClick={openCreateView}>
                Create View
              </div>
            </>
            }
          </div>
        </PopupModal>
      }
    </div>
  )
}

export const styleOfModal = { position: 'absolute', zIndex: 5 }

export const styleOfDeleteModal = {
  position: 'absolute', zIndex: 5,
  margin: 'auto',
  left: 0,
  backgroundColor: 'white',
  right: 0,
  maxWidth: 'max-content',
}

