import { useState } from 'react'
import { ReactComponent as Search } from '../../assets/svgIcon/topBar/Search.svg'
import { ReactComponent as Notification } from '../../assets/svgIcon/topBar/notifications.svg'
import { ReactComponent as Refresh } from '../../assets/svgIcon/topBar/refresh.svg'
import { ReactComponent as AccounLink } from '../../assets/svgIcon/topBar/link.svg'
import { CaretDown } from '../../assets/svgIcon/topBar/CaretDown'
import { PopupModal } from './PopupModal/PopupModal'
import ModalProfile from '../../assets/svgIcon/topBar/modalProfile.png'
import './TopBar.css'

export function TopBar({ title }) {
    const [isOpenProfile, setisOpenProfile] = useState(false)

    const openProfile = () => {
        setisOpenProfile(true)
    }
    const closeProfile = () => {
        setisOpenProfile(false)
    }
    return (
        <div className='topBar'>
            <h1>{title ? title : 'Purchase Orders'}</h1>

            <div className="rightBar">
                <div className="item">
                    <Search />
                </div>
                <div className="item">
                    <Refresh />
                </div>
                <div className="item">
                    <Notification />
                </div>
                <div onClick={openProfile} className={isOpenProfile ? 'activeProfile profile' : "profile"}>
                    <span className="nameIcon">Co</span>
                    Compostic
                    <CaretDown color={isOpenProfile ? '#1249BF' : 'black'} />

                    {isOpenProfile && <PopupModal styleOfModal={styleOfModal} closeModal={closeProfile}>
                        <div className="profileModal">
                            <div className="modalImg">
                                <img src={ModalProfile} alt="modal" />
                            </div>
                            <div className="profileIcon">
                                Co
                            </div>
                            <div className="modalDetails">
                                <h3>Compostic New Zealand</h3>
                                <h5>Admin</h5>
                                <h6>compostic@compostic.com</h6>
                                <div className="setting">
                                    <AccounLink />
                                    <span>
                                        Account Settings

                                    </span>
                                </div>
                            </div>

                        </div>
                    </PopupModal>}
                </div>
            </div>

        </div>
    )
}

const styleOfModal = {
    position: 'absolute',
    top: '50px',
    right: 0,
    zIndex: 11
}