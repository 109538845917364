const ListIcon = ({color= '#8C8CA2'})=>(
<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group 1261156691">
<path id="Vector" d="M15.0322 1.14319L6.99715 1.14319C6.46118 1.14319 6.02747 1.5769 6.02747 2.11287C6.02747 2.64884 6.46118 3.08256 6.99715 3.08256L15.0322 3.08256C15.5682 3.08256 16.0019 2.64884 16.0019 2.11287C16.0019 1.5769 15.5682 1.14319 15.0322 1.14319Z" fill={color}/>
<path id="Vector_2" d="M15.0322 7.16931L6.99715 7.16931C6.46118 7.16931 6.02747 7.60303 6.02747 8.139C6.02747 8.67497 6.46118 9.10868 6.99715 9.10868L15.0322 9.10868C15.5682 9.10868 16.0019 8.67497 16.0019 8.139C16.0019 7.60303 15.5682 7.16931 15.0322 7.16931Z" fill={color}/>
<path id="Vector_3" d="M15.0322 13.1946L6.99715 13.1946C6.46118 13.1946 6.02747 13.6283 6.02747 14.1643C6.02747 14.7002 6.46118 15.134 6.99715 15.134L15.0322 15.134C15.5682 15.134 16.0019 14.7002 16.0019 14.1643C16.0019 13.6292 15.5682 13.1946 15.0322 13.1946Z" fill={color}/>
<path id="Vector_4" d="M1.97248 0.139038C0.883474 0.139038 -0.00192261 1.02443 -0.00192262 2.11344C-0.00192263 3.2015 0.883474 4.08784 1.97248 4.08784C3.06149 4.08784 3.94688 3.20245 3.94688 2.11344C3.94594 1.02443 3.06054 0.139038 1.97248 0.139038ZM1.93744 2.11344C1.93744 2.09355 1.95259 2.0784 1.97248 2.0784C1.99142 2.0784 2.00752 2.09355 2.00752 2.11344C2.00657 2.15132 1.93744 2.15132 1.93744 2.11344Z" fill={color}/>
<path id="Vector_5" d="M1.97248 12.1904C0.884419 12.1904 -0.00192261 13.0758 -0.00192262 14.1648C-0.00192263 15.2529 0.883474 16.1383 1.97248 16.1383C3.06149 16.1383 3.94688 15.2529 3.94688 14.1639C3.94594 13.0758 3.06054 12.1904 1.97248 12.1904ZM1.93744 14.1639C1.93744 14.1449 1.95259 14.1288 1.97248 14.1288C1.99142 14.1288 2.00752 14.144 2.00752 14.1639C2.00657 14.2027 1.93744 14.2027 1.93744 14.1639Z" fill={color}/>
<path id="Vector_6" d="M1.97248 6.16479C0.883474 6.16479 -0.00192261 7.05114 -0.00192262 8.1392C-0.00192263 9.22726 0.883474 10.1136 1.97248 10.1136C3.06054 10.1136 3.94594 9.22726 3.94594 8.1392C3.94594 7.05114 3.06054 6.16479 1.97248 6.16479ZM1.93744 8.1392C1.93744 8.12026 1.95259 8.10416 1.97248 8.10416C1.99142 8.10416 2.00752 8.11931 2.00752 8.1392C2.00657 8.17708 1.93744 8.17708 1.93744 8.1392Z" fill={color}/>
</g>
</svg>
)


export  {ListIcon}