import { useEffect, useState } from 'react'
import { ListView } from './components/ListView/ListView.jsx'
import { BoardView } from './components/BoardView/BoardView.jsx'
import { CreateView } from './components/CreateView/CreateView.jsx'
import { OptionsForView } from './components/OptionsForView/OptionsForView.jsx'
import { BlurModal } from './components/BlurModal/BlurModal.jsx'
import { useSelector } from 'react-redux'
import { useGetConsignmentsQuery } from '../../../../store/apiSlice/shipmentApiSlice.js'
import { useGetMilestonesQuery, useGetViewQuery } from '../../../../store/apiSlice/newInterfaceApiSlice.js'
import './Shipment.css'

const Shipment = () => {
  const [boardView, setBoardView] = useState(false);
  const [shipmentOrders, setShipmentOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [allOrderNames, setAllOrderNames] = useState([])
  const [isOpenCreateView, setisOpenCreateView] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedCurrentView, setSelectedCurrentView] = useState({ value: 'Default View', sub_type: null })
  const [filteredOrderByStatus, setFilteredOrderByStatus] = useState(null)
  const [filteredOrderByMilestone, setFilteredOrderByMilestone] = useState(null)
  const [editView, setEditView] = useState(false);
  const { consignments, consignmentsParams } = useSelector(state => state.shipment);

  useGetMilestonesQuery();
  useGetConsignmentsQuery(new URLSearchParams(consignmentsParams).toString(), {
    refetchOnMountOrArgChange: true,
  });
  useGetViewQuery();
  const { allViews: allViewsFromServer, milestoneList } = useSelector(state => state.newInterface);

  useEffect(() => {
    const filteredOrders = {};
    const allOrders = [];
    const milestoneMapWithShipment = { Uncategorized: [] };
    if (consignments.results.length > 0) {
      milestoneList?.forEach(item => {
        milestoneMapWithShipment[item.id] = []
      })
      consignments.results.forEach((order, ind) => {
        allOrders.push(order.name);
        if (order.milestone) {
          milestoneMapWithShipment[order.milestone.id].push(order);
        } else {
          milestoneMapWithShipment.Uncategorized.push(order)
        }


        if (filteredOrders[order.status]) {
          filteredOrders[order.status].push(order)
        } else {
          filteredOrders[order.status] = [order]
        }
      })
    }
    setAllOrderNames(allOrders);
    setFilteredOrderByStatus(filteredOrders)
    setShipmentOrders(consignments.results);
    setFilteredOrderByMilestone(milestoneMapWithShipment);
  }, [consignments.results, milestoneList])

  const handleSelectOrder = (e) => {
    if (e.target.checked) {
      setSelectedOrder([...selectedOrder, e.target.value])
    }
    else {
      setSelectedOrder(selectedOrder.filter(orderId => orderId !== e.target.value))
    }
  }

  const closeCreateView = () => {
    setisOpenCreateView(false);
    if (editView) setEditView(false);
  }

  return (
    <div className='shipment'>
      <OptionsForView selectedOrderLength={selectedOrder.length}
        boardView={boardView}
        setisOpenCreateView={setisOpenCreateView}
        setSelectedCurrentView={setSelectedCurrentView}
        selectedCurrentView={selectedCurrentView}
        titleForRows={`Rows per page: ${rowsPerPage}`}
        setRowsPerPage={setRowsPerPage}
        setBoardView={setBoardView}
        setEditView={setEditView}
      />

      {isOpenCreateView &&
        <BlurModal>
          <CreateView
            closeCreateView={closeCreateView}
            boardView={boardView}
            editView={editView}
            selectedCurrentView={selectedCurrentView}
          />
        </BlurModal>
      }
      {
        boardView ? <BoardView selectedCurrentView={selectedCurrentView} filteredOrderByStatus={filteredOrderByStatus} filteredOrderByMilestone={filteredOrderByMilestone} />
          : <ListView
            allOrderNames={allOrderNames}
            handleSelectOrder={handleSelectOrder}
            rowsPerPage={rowsPerPage}
            tableHeaderList={allViewsFromServer.listView.allViews[selectedCurrentView?.value]}
            shipmentOrders={shipmentOrders} />
      }
    </div>
  )
}

export { Shipment }